import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../components/about.css";
import ScrollDetector from "../components/ScrollDetector";

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAbout {
        edges {
          node {
            biography {
              biography
            }
          }
        }
      }
    }
  `);
  console.log(data.allContentfulAbout.edges[0].node.biography.biography);
  return (
    <Layout>
      <SEO title="About" />
      <div className="biography-container">
        <ScrollDetector className="biography">
          <div className="biography-text">
            {data.allContentfulAbout.edges[0].node.biography.biography}
          </div>
        </ScrollDetector>
      </div>
    </Layout>
  );
}
